const data = [
  {
    title: "Is there a minimum time commitment?",
    content:
      "Amplify is a month-to-month commitment, meaning after the initial month's subscription, you can opt-out at any time. However, we strongly encourage participation in the program for a minimum period of 90-120 days. We have a wide spectrum of publishers in our network, and it may take a few months to pilot your product on a broad enough sample size to reach your full potential."
  },
  {
    title: "What does onboarding look like?",
    content:
      "Amplify is a month-to-month commitment, meaning after the initial month's subscription, you can opt-out at any time. However, we strongly encourage participation in the program for a minimum period of 90-120 days. We have a wide spectrum of publishers in our network, and it may take a few months to pilot your product on a broad enough sample size to reach your full potential."
  },
  {
    title:
      "Are there any additional fees outside of the subscription cost? Do I have to pay a commission on sales? ",
    content:
      "Amplify is a month-to-month commitment, meaning after the initial month's subscription, you can opt-out at any time. However, we strongly encourage participation in the program for a minimum period of 90-120 days. We have a wide spectrum of publishers in our network, and it may take a few months to pilot your product on a broad enough sample size to reach your full potential."
  },
  {
    title: "Do I get to help write the articles about my product?",
    content:
      "Amplify is a month-to-month commitment, meaning after the initial month's subscription, you can opt-out at any time. However, we strongly encourage participation in the program for a minimum period of 90-120 days. We have a wide spectrum of publishers in our network, and it may take a few months to pilot your product on a broad enough sample size to reach your full potential."
  },
  {
    title: "Do I get to pick the publishers?",
    content:
      "Amplify is a month-to-month commitment, meaning after the initial month's subscription, you can opt-out at any time. However, we strongly encourage participation in the program for a minimum period of 90-120 days. We have a wide spectrum of publishers in our network, and it may take a few months to pilot your product on a broad enough sample size to reach your full potential."
  },
  {
    title: "How often do I get paid for orders?",
    content:
      "Amplify is a month-to-month commitment, meaning after the initial month's subscription, you can opt-out at any time. However, we strongly encourage participation in the program for a minimum period of 90-120 days. We have a wide spectrum of publishers in our network, and it may take a few months to pilot your product on a broad enough sample size to reach your full potential."
  },
  {
    title: "How do I receive orders?",
    content:
      "Amplify is a month-to-month commitment, meaning after the initial month's subscription, you can opt-out at any time. However, we strongly encourage participation in the program for a minimum period of 90-120 days. We have a wide spectrum of publishers in our network, and it may take a few months to pilot your product on a broad enough sample size to reach your full potential."
  },
  {
    title: "Do you offer backlinks within articles?",
    content:
      "Amplify is a month-to-month commitment, meaning after the initial month's subscription, you can opt-out at any time. However, we strongly encourage participation in the program for a minimum period of 90-120 days. We have a wide spectrum of publishers in our network, and it may take a few months to pilot your product on a broad enough sample size to reach your full potential."
  }
];

export default data;
