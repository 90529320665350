import React from "react";
import styled from "styled-components";
import { Headline16 } from "./styles/Headings";
import { Label14 } from "./styles/BodyTypes";
import { black1, black2, brandColor3 } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";

const Title = styled(Headline16)`
  margin: 4px 0 0;
  color: inherit;
`;

const Description = styled(Label14)`
  margin: 0;
  color: inherit;
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 3;
  background-color: transparent;
  cursor: pointer;
  color: ${black1};
  transition: color 0.3s linear;
  -webkit-tap-highlight-color: rgba(255, 201, 0, 0.4);
  border-radius: 31.5px;
`;

const Right = styled(Section)`
  color: ${black2};
`;

const Left = styled(Section)``;

const Slider = styled.div`
  position: absolute;
  z-index: 1;
  width: calc(50% - 2px);
  top: 2px;
  left: 2px;
  bottom: 2px;
  background: ${brandColor3};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  border-radius: 31.5px;
  transition: transform 0.3s linear;
`;

const Wrapper = styled.div`
  width: 288px;
  height: 62px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 31.5px;
  overflow: hidden;
  position: relative;
  background-color: #dedede;

  @media (${mediaMedium}) {
    width: 317px;
    height: 67px;
  }

  &.active {
    ${Slider} {
      transform: translateX(100%);
    }

    ${Right} {
      color: ${black1};
    }

    ${Left} {
      color: ${black2};
    }
  }
`;

const PlanToggle = ({ alt, event }) => {
  return (
    <Wrapper className={alt ? "active" : ""} onClick={event}>
      <Slider />
      <Left>
        <Title as="span">monthly</Title>
        <Description as="span">No commitment</Description>
      </Left>
      <Right>
        <Title as="span">annually</Title>
        <Description as="span">Save 20% more</Description>
      </Right>
    </Wrapper>
  );
};

export default PlanToggle;
