import React, { useState } from "react";
import styled from "styled-components";
import priceFormat from "./helpers/PriceFormat";
import { planOptions } from "../config/plans";
import { fontCatamara, fontProxima } from "./styles/variables";
import { brandColor1, brandColor1Shade, white } from "./styles/Colors";
import IconCaretDown from "./icons/IconCaretDown";
import AnimateHeight from "react-animate-height";

const Card = styled.article`
  font-family: ${fontProxima};
  background-color: ${white};
  margin: 16px 0;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 29px 25px 21px;
`;

const Content = styled(Top)`
  padding-bottom: 0;
  padding-top: 0;
`;

const Bottom = styled(Top)`
  padding: 9px 25px;
  background-color: #dedede;
  flex-direction: row;
  justify-content: center;
  font-family: ${fontCatamara};
  font-weight: 300;
  font-size: 14px;
  color: #494949;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  cursor: pointer;

  svg {
    margin-left: 14px;
    transition: 0.3s ease-in-out transform;
    transform: rotate(${(props) => (props.active ? "180deg" : "0")});
  }
`;

const Title = styled.h3`
  font-size: 20px;
  color: #181818;
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0 0 8px;
`;

const Price = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 900;
  color: #ffc900;
  letter-spacing: -0.5px;
  text-align: center;
  line-height: 20px;
`;

const Value = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #ababab;
  letter-spacing: -0.35px;
  text-align: center;
  line-height: 18px;
  margin: 8px 0 0;
`;

const Link = styled.a`
  margin: 15px 0 0;
  display: inline-block;
  font-family: ${fontCatamara};
  font-weight: 300;
  font-size: 14px;
  color: ${brandColor1};
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  transition: 0.2s color;

  &:hover {
    color: ${brandColor1Shade};
  }
`;

const OptionRow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-top: 1px solid #eeeeee;
  padding: 13px 0 14px;
  width: 100%;
  text-align: center;
`;

const OptionName = styled.span`
  font-family: ${fontCatamara};
  font-weight: 300;
  font-size: 14px;
  color: #181818;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
`;

const OptionDetail = styled(OptionName)`
  font-weight: 800;
`;

const PricingCard = ({ item, alt, id }) => {
  const [active, setActive] = useState(false);

  let price = alt ? item.priceAnnually ?? item.price : item.price;
  if (!isNaN(price)) {
    price = priceFormat.format(price);
  }

  const handleClick = () => setActive(!active);

  return (
    <Card>
      <Top>
        <Title>{item.title} Plan</Title>
        <Price>{price}</Price>
        {item.totalValue && (
          <Value>Total Value {priceFormat.format(item.totalValue)}</Value>
        )}
        <Link>Sign Up</Link>
      </Top>

      <AnimateHeight duration={300} height={active ? "auto" : 0}>
        <Content>
          {planOptions.map((option, index) => {
            if (item.options[index] === "") {
              return "";
            }

            return (
              <OptionRow key={`pricing-card-${id}-option-${index}`}>
                <OptionName>{option.title}</OptionName>
                {item.options[index] !== true && (
                  <OptionDetail>{item.options[index]}</OptionDetail>
                )}
              </OptionRow>
            );
          })}
        </Content>
      </AnimateHeight>

      <Bottom onClick={handleClick} active={active}>
        See Features <IconCaretDown />
      </Bottom>
    </Card>
  );
};

export default PricingCard;
