import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import HeroStories from "../HeroStories";
import { black1, white } from "../styles/Colors";

const data = [
  {
    title: "Zuitte generates $125k in sales in 5 months",
    topic: "apps + software",
    link: "/slides/StackCommerce_Case_Study_Zuitte.pdf"
  },
  {
    title: "BioBidet sells 1,145 bidets in just 6 weeks",
    topic: "consumer products",
    link: "/slides/StackCommerce_Case_Study_Bio_Bidet.pdf"
  },
  {
    title: "Verve Culture moves from wholesale to D2C commerce",
    topic: "kitchenware",
    link: "/slides/StackCommerce_Case_Study_Verve.pdf"
  }
];

const AmplifyStories = () => {
  const images = useStaticQuery(graphql`
    query {
      image0: file(relativePath: { eq: "71-amplify-testimonial-apps.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image1: file(
        relativePath: { eq: "71-amplify-testimonial-products.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(
        relativePath: { eq: "71-amplify-testimonial-kitchenware.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 548, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return (
    <HeroStories
      color={white}
      backgroundColor={black1}
      data={data}
      images={images}
    />
  );
};

export default AmplifyStories;
