import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import { graphql, useStaticQuery } from "gatsby";
import { Headline64 } from "../styles/Headings";
import { Body24 } from "../styles/BodyTypes";
import { mediaMedium } from "../styles/variables";
import CardSnippet from "../CardSnippet";

const Wrapper = styled.div`
  padding: 96px 0;

  @media (${mediaMedium}) {
    padding: 152px 0 160px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 100%;
  padding-top: 42px;

  @media (${mediaMedium}) {
    padding-top: 66px;
    grid-template-columns: repeat(12, 1fr);

    * {
      grid-column-end: span 8;

      &:nth-child(n + 2):nth-child(-n + 3),
      &:nth-child(n + 6):nth-child(-n + 7) {
        grid-column-end: span 4;
      }
    }
  }
`;

const Row = styled.div`
  p {
    margin-top: 48px;
    max-width: 555px;
  }

  @media (${mediaMedium}) {
    text-align: center;

    p {
      margin: 64px auto 24px;
      text-align: center;
    }
  }
`;

const data = [
  {
    title: "Blanket ID",
    description: "Pet Protection ID Tags",
    theme: { bgPosition: "25% 10%" }
  },
  {
    title: "PersonalVPN",
    description: "Global Internet Privacy"
  },
  {
    title: "SellFull",
    description: "White Label Website Builder",
    theme: { bgPosition: "50% 0%" }
  },
  {
    title: "Fugoo",
    description: "Loud & Rough Bluetooth Speaker",
    alt: true
  },
  {
    title: "Club 1 Hotels",
    description: "Leading Luxury Travel Club",
    theme: { bgPosition: "35% 0%" }
  },
  {
    title: "SocialProof by ZapERP",
    description: "Increase Conversions & Site Growth",
    theme: { bgPosition: "0% 10%" }
  },
  {
    title: "Sanibeam",
    description: "Portable UVC Sanitizer",
    alt: true
  },
  {
    title: "DudeRobe",
    description: "A Modern Bathrobe Designed for Modern Guys",
    theme: { bgPosition: "15% 0%", maxWidth: "160px" }
  }
];

const OffsetBrandsSection = ({ images }) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Headline64>
            We’re trusted by leading brands of all shapes and sizes
          </Headline64>
          <Body24>
            We work with software startups, Kickstarter brands, D2C brands,
            established companies, and beyond.
          </Body24>
        </Row>

        <Grid>
          {data.map((item, key) => (
            <CardSnippet
              key={"success-story-" + key}
              image={images["image" + key].childImageSharp.fluid}
              {...item}
            />
          ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};

const OffsetBrands = () => {
  const images = useStaticQuery(graphql`
    query {
      image0: file(relativePath: { eq: "73-blank-id.png" }) {
        childImageSharp {
          fluid(maxWidth: 744, maxHeight: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image1: file(relativePath: { eq: "73-peronal-vpn.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(relativePath: { eq: "73-sellfull.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image3: file(relativePath: { eq: "73-fugoo.png" }) {
        childImageSharp {
          fluid(maxWidth: 744, maxHeight: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image4: file(relativePath: { eq: "73-club-1hotels.png" }) {
        childImageSharp {
          fluid(maxWidth: 744, maxHeight: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image5: file(relativePath: { eq: "73-social-proof.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image6: file(relativePath: { eq: "73-sanibeam.png" }) {
        childImageSharp {
          fluid(maxWidth: 360, maxHeight: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image7: file(relativePath: { eq: "73-dude-robe.png" }) {
        childImageSharp {
          fluid(maxWidth: 744, maxHeight: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <OffsetBrandsSection images={images} />;
};

export default OffsetBrands;
