import React from "react";
import HeroDivideRight from "../HeroDivideRight";
import { brandColor2, gray4 } from "../styles/Colors";
import TextHighlight from "../styles/TextHighlight";
import OffsetBrands from "../brands/OffsetBrands";
import AmplifyHowItWorks from "../brands/AmplifyHowItWorks";
import AmplifyNetwork from "../brands/AmplifyNetwork";
import HeroTestimonials from "../HeroTestimonials";
import AmplifyStories from "../brands/AmplifyStories";
import PlanOptions from "../PlanOptions";
import AmplifyFaq from "../brands/AmplifyFaq";
import HeroBlueCTA from "../HeroBlueCTA";
import { Link } from "gatsby";

// Images
import imageTestimonialOne from "../../images/70-amplify-testimonial-silviu-miroiu.png";
import imageTestimonialTwo from "../../images/70-amplify-testimonial-tetiana-radkevych.png";
import imageTestimonialThree from "../../images/70-amplify-testimonial-arthur-menard.png";

const testimonialData = [
  {
    image: imageTestimonialOne,
    description: "Silviu Miroiu @ internnic",
    content:
      '"The partnership with Stack successfully boosted the sales of all our products and services at internnic.com. In fact, our sales increased by 300% YoY in the first 6 months of 2020. Stack certainly surpassed our expectations!" '
  },
  {
    image: imageTestimonialTwo,
    description: "Tetiana Radkevych @ Eladamar Studio",
    content:
      "“The partnership with Stack has taken our business to a new level. The Amplify program has helped us promote our products to a large number of customers. Together with Amplify, we continue to get great results and sales even while we sleep.”"
  },
  {
    image: imageTestimonialThree,
    description: "Arthur Menard de Calenge @ Lambs",
    content:
      "“Working with StackCommerce has been an awesome and easy experience. They did a great job at setting up our campaigns rapidly and the early results are very promising.”"
  }
];

const BrandsAmplifyDefer = () => {
  return (
    <>
      <HeroDivideRight
        theme={{ background: gray4, lines: brandColor2 }}
        contentTag={"div"}
      >
        <p>
          Executing a PR strategy is challenging and expensive. Amplify gets you
          guaranteed continuous exposure for your brand on some of the best
          media sites and email newsletters on the web — so you don’t have to
          lift a finger.
        </p>
        <p>
          <TextHighlight theme={{ background: gray4 }}>
            Your viral moment is waiting.
          </TextHighlight>
        </p>
      </HeroDivideRight>

      <OffsetBrands />

      <AmplifyHowItWorks />

      <AmplifyNetwork />

      <HeroTestimonials data={testimonialData} />

      <AmplifyStories />

      <PlanOptions />

      <AmplifyFaq />

      <HeroBlueCTA
        title="READY TO"
        outline="START?"
        description="It’s easy—just tell us a little bit about yourself and you’ll be on your way."
        link={{ to: "/contact/", state: { from: "brand" }, as: Link }}
      />
    </>
  );
};

export default BrandsAmplifyDefer;
