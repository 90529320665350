import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";
import { Headline64 } from "../styles/Headings";
import { black1, white } from "../styles/Colors";
import MetricSnippet from "../MetricSnippet";
import { mediaMedium } from "../styles/variables";

const Wrapper = styled.div`
  background-color: ${black1};
  padding: 96px 0;
  position: relative;
  overflow: hidden;

  @media (${mediaMedium}) {
    padding: 152px 0 151px;
  }
`;

const Grid = styled.div`
  display: grid;
  padding-top: 56px;
  grid-row-gap: 74px;
  position: relative;
  z-index: 2;

  br {
    display: none;
  }

  @media (${mediaMedium}) {
    padding-top: 96px;
    grid-template-columns: 300px 300px;
    grid-row-gap: 134px;
    align-items: center;
    justify-content: space-around;
    text-align: center;

    br {
      display: inline-block;
    }
  }
`;

const Row = styled.div`
  position: relative;
  z-index: 2;

  @media (${mediaMedium}) {
    text-align: center;
  }
`;

const Background = styled(BackgroundImage)`
  position: absolute !important;
  bottom: 0;
  width: 200%;
  left: -50%;
  padding-top: 120% !important;
  pointer-events: none;

  &:before,
  &:after {
    background-position: center top !important;
  }

  @media (${mediaMedium}) {
    width: 100%;
    height: 50%;
    left: 0;
    padding-top: 50% !important;
  }
`;

const AmplifyNetworkSection = ({ images }) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Headline64 theme={{ color: white }}>
            Tap Into Our Network and Instantly Expand Your Reach
          </Headline64>
        </Row>
        <Grid>
          <div>
            <MetricSnippet suffix="K+" number="1" description="Publishers" />
          </div>
          <div>
            <MetricSnippet
              suffix="M+"
              number="5"
              description="Email Subscribers"
            />
          </div>
          <div>
            <MetricSnippet
              suffix="K+"
              number="32"
              description="Average Impressions <br/> per Product"
            />
          </div>
          <div>
            <MetricSnippet
              suffix="M+"
              number="10"
              description="Average Total Shop  <br/> Impressions per Month"
            />
          </div>
        </Grid>
      </Container>
      <Background fluid={images.file.childImageSharp.fluid} />
    </Wrapper>
  );
};

const AmplifyNetwork = () => {
  const images = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "75-amplify-network.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [1000, 1400]
            maxWidth: 1440
            maxHeight: 660
            quality: 100
          ) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <AmplifyNetworkSection images={images} />;
};

export default AmplifyNetwork;
