import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import { graphql, useStaticQuery } from "gatsby";
import { Headline80 } from "../styles/Headings";
import { mediaMedium } from "../styles/variables";
import { gray4 } from "../styles/Colors";
import HeroSection from "../HeroSection";
import imageTop from "../../images/74-decoration-top.png";
import imageBody from "../../images/74-decoration-body.png";
import { FadeInRight } from "../styles/animations";

const Wrapper = styled.div`
  padding: 96px 0 24px;
  background-color: ${gray4};
  position: relative;
  overflow: hidden;

  @media (${mediaMedium}) {
    padding: 144px 0 0;
  }
`;

const Row = styled.div`
  max-width: 555px;
  position: relative;
  z-index: 2;
`;

const Decoration = styled.div`
  width: 100%;
  max-width: 1144px;
  display: none;

  @media (${mediaMedium}) {
    display: block;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      max-width: 1144px;
    }

    &:before {
      background: url("${imageTop}") top center no-repeat;
      background-size: contain;
      z-index: 1;
      height: 800px;
    }

    &:after {
      top: 0;
      bottom: 0;
      background: url("${imageBody}") center repeat;
      background-size: contain;
    }
  }

  @media (min-width: 1420px) {
    &:before,
    &:after {
      left: 10%;
      top: -2%;
    }

    &:before,
    &:after {
      left: 10%;
    }
  }
`;

const data = [
  {
    title: "GO LIVE",
    description:
      "Your product goes live across hundreds of publisher shops in a click."
  },
  {
    title: "GET DISCOVERED",
    description:
      "Get discovered through affiliate content written by our Content Studio for top media sites."
  },
  {
    title: "BOOST YOUR SEO",
    description:
      "Improve your search rankings with product mentions on sites with high domain authority."
  },
  {
    title: "GET EMAILED",
    description: "Get included in emails sent daily to 4MM+ subscribers.",
    alt: true
  },
  {
    title: "SCALE ADS",
    description:
      "Our in-house performance marketing agency leverages our publishers’ audiences to drive campaign scale.",
    theme: { bgPosition: "35% 0%" }
  },
  {
    title: "TARGET NEW CUSTOMERS",
    description:
      "Reach different demographics and audiences with our wide-spectrum of publisher partners."
  }
];

const AmplifyHowItWorksSection = ({ images }) => {
  return (
    <Wrapper>
      <Decoration />
      <Container>
        <Row>
          <Headline80>How it Works</Headline80>
        </Row>
      </Container>
      {data.map((item, key) => (
        <HeroSection
          key={"how-it-works-" + key}
          top={"0" + (key + 1)}
          title={item.title}
          backgroundColor="transparent"
          theme={{
            imageTop: "2%",
            imageLeft: "50%",
            imgWidth: "calc(100% + 200px)",
            imgTransform: "-100px",
            contentMarginBottom: "-2px",
            overflowWidth: "900",
            paddingDesktop: "188px 0"
          }}
          images={{
            desktop: images[`image` + key].childImageSharp.fluid,
            mobile: images[`imageMedia` + key].childImageSharp.fluid
          }}
          animation={FadeInRight}
        >
          {item.description}
        </HeroSection>
      ))}
    </Wrapper>
  );
};

const AmplifyHowItWorks = () => {
  const images = useStaticQuery(graphql`
    query {
      image0: file(relativePath: { eq: "74-amplifly-section-1-desktop.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [1000, 1400]
            maxWidth: 1089
            maxHeight: 680
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image1: file(relativePath: { eq: "74-amplifly-section-2-desktop.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [1000, 1400]
            maxWidth: 1089
            maxHeight: 680
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image2: file(relativePath: { eq: "74-amplifly-section-3-desktop.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [1000, 1400]
            maxWidth: 1089
            maxHeight: 680
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image3: file(relativePath: { eq: "74-amplifly-section-4-desktop.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [1000, 1400]
            maxWidth: 1089
            maxHeight: 680
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image4: file(relativePath: { eq: "74-amplifly-section-5-desktop.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [1000, 1400]
            maxWidth: 1089
            maxHeight: 680
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      image5: file(relativePath: { eq: "74-amplifly-section-6-desktop.png" }) {
        childImageSharp {
          fluid(
            srcSetBreakpoints: [1000, 1400]
            maxWidth: 1089
            maxHeight: 680
            quality: 100
          ) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      imageMedia0: file(
        relativePath: { eq: "74-amplifly-section-1-media.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1114, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      imageMedia1: file(
        relativePath: { eq: "74-amplifly-section-2-media.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1110, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      imageMedia2: file(
        relativePath: { eq: "74-amplifly-section-3-media.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1110, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      imageMedia3: file(
        relativePath: { eq: "74-amplifly-section-4-media.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1104, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      imageMedia4: file(
        relativePath: { eq: "74-amplifly-section-5-media.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1096, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      imageMedia5: file(
        relativePath: { eq: "74-amplifly-section-6-media.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1096, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return <AmplifyHowItWorksSection images={images} />;
};

export default AmplifyHowItWorks;
