import React, { useState } from "react";
import styled from "styled-components";
import AnimateHeight from "react-animate-height";
import { Body16 } from "./styles/BodyTypes";
import Image from "./Image";
import { black1 } from "./styles/Colors";
import { fontCatamara } from "./styles/variables";

const Wrapper = styled.div`
  border-bottom: 1px solid #ababab;
  padding-bottom: 10px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 28px 0 17px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`;

const Title = styled.h4`
  color: ${black1};
  font-family: ${fontCatamara};
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.4s, opacity 0.4s;
  transform-origin: center center;
  margin-left: 24px;

  &:hover {
    opacity: 0.8;
  }

  &.active {
    transform: rotate(180deg);
  }
`;

const Content = styled(Body16)`
  margin: 0 0 16px;
`;

const AccordionSnippet = ({ title, content }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Wrapper>
      <Top onClick={handleClick}>
        <Title>{title}</Title>
        <Icon className={open ? "active" : ""}>
          <Image src="/images/icon-arrow-down.svg" width={12} height={8} />
        </Icon>
      </Top>
      <AnimateHeight duration={300} height={open ? "auto" : 0}>
        <Content>{content}</Content>
      </AnimateHeight>
    </Wrapper>
  );
};

export default AccordionSnippet;
