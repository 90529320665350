import React, { useState } from "react";
import styled from "styled-components";
import { Headline64 } from "./styles/Headings";
import { gray4 } from "./styles/Colors";
import Container from "./styles/Container";
import PricingTable from "./PricingTable";
import PlanToggle from "./PlanToggle";
import { mediaMedium } from "./styles/variables";
import PricingCards from "./PricingCards";

const Wrapper = styled.div`
  background-color: ${gray4};
  padding: 96px 0;

  @media (${mediaMedium}) {
    padding: 152px 0 160px;
  }
`;

const Row = styled.div`
  text-align: center;
  margin: 0 auto 36px;

  &:first-child {
    width: 200px;
  }

  &:nth-child(2) {
    margin: 0 0 37px;
  }

  @media (${mediaMedium}) {
    margin: 0 0 50px;

    &:first-child {
      width: 100%;
    }

    &:nth-child(2) {
      margin: 0 0 63px;
    }
  }
`;

const PlanOptions = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Headline64>Our Plan Options</Headline64>
        </Row>
        <Row>
          <PlanToggle alt={active} event={handleClick} />
        </Row>
      </Container>
      <PricingTable alt={active} />
      <PricingCards alt={active} />
    </Wrapper>
  );
};

export default PlanOptions;
