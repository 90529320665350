import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { black1, white } from "./styles/Colors";
import { fontCatamara, mediaMedium } from "./styles/variables";
import MoveInDiv from "./MoveInDiv";

const Background = styled(BackgroundImage)`
  min-height: 256px;
  padding: 32px 24px;
  overflow: hidden;

  &:after,
  &:before {
    background-position: ${(props) => props.theme.bgPosition} !important;
  }

  @media (${mediaMedium}) {
    height: 384px;
    background-position: center;
    background-size: cover;

    &:before {
      display: none;
    }
  }
`;

const Space = styled.div`
  content: " ";
  padding-top: 78.29%;
  width: 20px;
`;

const Title = styled.span`
  margin: 0;
  display: block;
  font-family: ${fontCatamara};
  font-weight: 800;
  text-decoration: none;
  color: ${(props) => (props.altColor ? white : black1)};
  letter-spacing: 0;
  position: absolute;
  left: 24px;
  top: 32px;
`;

const Description = styled(Title)`
  font-weight: 300;
  top: 59px;
  max-width: ${(props) => props.theme.maxWidth ?? "100%"};

  @media (${mediaMedium}) {
    max-width: 100%;
  }
`;

const CardSnippet = ({ title, description, image, alt, theme }) => {
  return (
    <MoveInDiv>
      <Background fluid={image} theme={theme}>
        <Title altColor={alt}>{title}</Title>
        <Description theme={theme}>{description}</Description>
        <Space />
      </Background>
    </MoveInDiv>
  );
};

export default CardSnippet;
