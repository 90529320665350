import React from "react";
import styled from "styled-components";
import IconCheck from "./icons/IconCheck";
import IconQuestion from "./icons/IconQuestion";
import { pricingPlans, planOptions } from "../config/plans";
import { Headline16 } from "./styles/Headings";
import {
  black1,
  brandColor1,
  brandColor1Shade,
  brandColor3,
  gray4,
  white
} from "./styles/Colors";
import priceFormat from "./helpers/PriceFormat";
import Container from "./styles/Container";
import { Label14 } from "./styles/BodyTypes";
import { fontCatamara, fontProxima, mediaMedium } from "./styles/variables";

const Grid = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 208px) repeat(7, minmax(60px, 128px));
  align-items: stretch;
  grid-column-gap: 4px;
  width: 100%;
`;

const Wrapper = styled.div`
  background-color: ${black1};
  display: none;

  @media (${mediaMedium}) {
    display: block;
  }
`;

const GridWrapper = styled.div`
  background-color: ${gray4};
`;

const Top = styled(Grid)``;

const TopHead = styled.div`
  padding: 30px 30px 20px;
  background-color: ${white};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const BottomHead = styled(TopHead)``;

const Middle = styled(Grid)``;

const Title = styled(Headline16)`
  margin: 0 0 17px;
`;

const Price = styled(Headline16)`
  color: ${brandColor3};
  margin: 0;
`;

const Value = styled.span`
  margin: 8px 0 0;
  font-family: ${fontProxima};
  font-weight: 200;
  font-size: 12px;
  color: #ababab;
  letter-spacing: -0.3px;
  text-align: center;
  line-height: 16px;
`;

const OptionHead = styled(Label14)`
  display: inline-flex;
  align-items: center;
  color: ${white};
  min-height: 71px;
  border-bottom: 1px solid rgba(171, 171, 171, 0.5);
`;

const OptionCell = styled(Label14)`
  padding: 0 20px;
  background-color: ${white};
  min-height: 71px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid rgba(171, 171, 171, 0.5);

  &:nth-child(n + 2):nth-child(-n + 8) {
    border-top: 1px solid rgba(171, 171, 171, 0.5);
  }

  svg {
    width: 17px;
  }
`;

const Tooltip = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10%, -50%);
  width: 250px;
  padding: 20px;
  display: flex;
  background: ${white};
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.15);
  font-family: ${fontCatamara};
  font-size: 14px;
  color: #181818;
  letter-spacing: 0;
  line-height: 24px;
  transition: 0.3s opacity ease-in-out;
  pointer-events: none;
  user-select: none;
  opacity: 0;
  visibility: hidden;
`;

const TooltipIcon = styled.span`
  color: ${white};
  display: inline-block;
  margin: 0 0 0 8px;
  transform: translateY(2px);
  position: relative;
  cursor: pointer;

  &:hover {
    ${Tooltip} {
      pointer-events: all;
      user-select: auto;
      opacity: 1;
      visibility: visible;
    }
  }

  svg {
    width: 12px;
    height: 12px;
  }
`;

const Link = styled(Label14)`
  text-decoration: none;
  color: ${brandColor1};
  transition: color 0.2s;

  svg {
    margin-left: 16px;
  }

  &:hover {
    cursor: pointer;
    color: ${brandColor1Shade};
  }
`;

const PricingTable = ({ alt }) => {
  const plansLength = pricingPlans.length;
  const topContent = [<span key="space" />];
  const bottomContent = [<span key="space-links" />];
  const middleContent = [];

  pricingPlans.map((item, key) => {
    let price = alt ? item.priceAnnually ?? item.price : item.price;

    if (!isNaN(price)) {
      price = priceFormat.format(price);
    }
    topContent.push(
      <TopHead key={`table-top-` + key}>
        <Title>{item.title} Plan</Title>

        <Price>{price}</Price>

        {item.totalValue && (
          <Value>
            Total Value <br /> {priceFormat.format(item.totalValue)}
          </Value>
        )}
      </TopHead>
    );

    bottomContent.push(
      <BottomHead key={`table-bottom-` + key}>
        <Link>Sign Up</Link>
      </BottomHead>
    );

    return "";
  });

  planOptions.map((item, key) => {
    middleContent.push(
      <OptionHead key={`table-content-description-` + key} as="div">
        <span>
          {item.title}
          {item.toolTip !== "" && (
            <TooltipIcon>
              <IconQuestion />
              <Tooltip>{item.toolTip}</Tooltip>
            </TooltipIcon>
          )}
        </span>
      </OptionHead>
    );

    for (let i = 0; i < plansLength; i++) {
      const item = pricingPlans[i].options[key];

      if (item === true) {
        middleContent.push(
          <OptionCell key={`table-content-option-check-${key}-${i}`}>
            <IconCheck />
          </OptionCell>
        );
      } else {
        middleContent.push(
          <OptionCell key={`table-content-option-${key}-${i}`} as="div">
            {pricingPlans[i].options[key]}
          </OptionCell>
        );
      }
    }
    return "";
  });

  return (
    <Wrapper>
      <GridWrapper>
        <Container>
          <Top>{topContent}</Top>
        </Container>
      </GridWrapper>
      <Container>
        <Middle>{middleContent}</Middle>
      </Container>
      <GridWrapper>
        <Container>
          <Top>{bottomContent}</Top>
        </Container>
      </GridWrapper>
    </Wrapper>
  );
};

export default PricingTable;
