import React from "react";

const IconCheck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 13">
      <path
        fill="#181818"
        fillRule="evenodd"
        d="M15.58 0L17 1.44 7.08 11.56 5.67 13l-1.42-1.44L0 7.22l1.42-1.44 4.25 4.33L15.58 0z"
      />
    </svg>
  );
};

export default IconCheck;
