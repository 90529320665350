import React from "react";
import styled from "styled-components";
import { pricingPlans } from "../config/plans";
import Container from "./styles/Container";
import PricingCard from "./PricingCard";
import { mediaMedium } from "./styles/variables";

const Wrapper = styled(Container)`
  @media (${mediaMedium}) {
    display: none;
  }
`;

const PricingCards = ({ alt }) => {
  return (
    <Wrapper>
      {pricingPlans.map((item, key) => {
        return (
          <PricingCard
            key={`pricing-card-${key}`}
            alt={alt}
            item={item}
            id={key}
          />
        );
      })}
    </Wrapper>
  );
};

export default PricingCards;
