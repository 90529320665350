import React from "react";
import styled from "styled-components";
import Container from "../styles/Container";
import { mediaMedium } from "../styles/variables";
import AccordionSnippet from "../AccordionSnippet";
import { Headline64 } from "../styles/Headings";
import faqData from "../../config/faq";

const Wrapper = styled(Container)`
  padding-top: 96px;
  padding-bottom: 96px;

  @media (${mediaMedium}) {
    padding-top: 152px;
    padding-bottom: 160px;
  }
`;

const Row = styled.div`
  text-align: center;
  margin-bottom: 40px;

  @media (${mediaMedium}) {
    margin-bottom: 88px;
  }
`;

const AmplifyFaq = () => {
  return (
    <Wrapper>
      <Row>
        <Headline64>FAQ</Headline64>
      </Row>
      {faqData.map((item, key) => (
        <AccordionSnippet key={`accordion-` + key} {...item} />
      ))}
    </Wrapper>
  );
};

export default AmplifyFaq;
