export const planOptions = [
  {
    title: "Listings Across Stack-Branded Shops",
    toolTip:
      "Get listings across our 4 owned and operated marketplaces (where applicable). Our marketplaces include StackSocial, Citizen Goods, Joyus, and Skillwise."
  },
  { title: "Listings Across 200+ Publisher-Branded Shop Network", toolTip: "" },
  { title: "Email Newsletter Inclusion", toolTip: "" },
  { title: "Editorial Mentions in Publisher Article(s) ", toolTip: "" },
  { title: "Dedicated Editorial Publisher Article(s)", toolTip: "" },
  { title: "Paid Marketing Management", toolTip: "" },
  { title: "Accelerated Payments (2x Per Month)", toolTip: "" },
  { title: "Dedicated SMS, Web Push, or Messenger Placement", toolTip: "" },
  { title: "Full-Day Shops Homepage Takeover Placement", toolTip: "" },
  { title: "20% Contribution Toward Paid Ad Marketing Spend", toolTip: "" },
  { title: "Featured Placement in Email Newsletter ", toolTip: "" }
];

export const pricingPlans = [
  {
    title: "Entry",
    price: "49",
    totalValue: "49",
    priceAnnually: "TBD",
    options: [true, "", "", "", "", "", "", "", "", "", ""]
  },
  {
    title: "Basic",
    price: "99",
    totalValue: "148",
    priceAnnually: "TBD",
    options: [true, true, "", "", "", "", "", "", "", "", ""]
  },
  {
    title: "Silver ",
    price: "499",
    totalValue: "1046",
    priceAnnually: "TBD",
    options: [true, true, true, "1", "", "", "", "", "", "", ""]
  },
  {
    title: "Gold",
    price: "999",
    totalValue: "3743",
    priceAnnually: "TBD",
    options: [true, true, true, "1", "1", true, true, "", "", "", ""]
  },
  {
    title: "Platinum",
    price: "2999",
    totalValue: "11783",
    priceAnnually: "TBD",
    options: [true, true, true, "3", "2", true, true, true, true, true, ""]
  },
  {
    title: "Entry",
    price: "4999",
    totalValue: "20534",
    priceAnnually: "TBD",
    options: [true, true, true, "5", "3", true, true, true, true, true, true]
  },
  {
    title: "Enterprise",
    price: "Custom plans available",
    options: [
      true,
      true,
      true,
      "Dependent on Budget",
      "Dependent on Budget",
      true,
      true,
      true,
      true,
      true,
      "Dependent on Budget"
    ]
  }
];
