import React from "react";

const IconQuestion = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M6 0a6 6 0 110 12A6 6 0 016 0zm0 1a5 5 0 100 10A5 5 0 006 1zm-.47 6.69c.19 0 .34.06.47.18.12.13.19.29.19.47 0 .19-.07.34-.2.47a.64.64 0 01-.46.19.65.65 0 01-.67-.66c0-.18.07-.34.2-.46a.65.65 0 01.47-.2zm.3-5c.59 0 1.03.15 1.35.44.3.29.47.68.47 1.17 0 .32-.15.65-.43 1s-.63.63-1.06.86l-.15 1.23-.83-.35-.07-1.49c.44-.14.79-.32 1.06-.54.27-.22.4-.45.4-.69 0-.2-.07-.36-.21-.48a.83.83 0 00-.55-.18c-.2 0-.45.05-.73.15-.27.1-.55.24-.84.43L4.1 3.19a3.1 3.1 0 011.75-.5z"
      />
    </svg>
  );
};

export default IconQuestion;
